/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global styles */
body {
  background-image: url("https://st2.depositphotos.com/5473448/8004/i/450/depositphotos_80049310-stock-photo-star-field-golden-and-silver.jpg");
  font-family: Arial, sans-serif;
  color: #fff;
}

/* Container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Title styles */
.title {
  font-size: 4vw; /* Responsive font size */
  text-align: center;
  color: purple;
  text-shadow: 2px 2px 0 white, -2px -2px 0 white, -2px 2px 0 white, 2px -2px 0 white;
}

.title h5 {
  font-style: italic;
  letter-spacing: 1px; /* Reduced letter-spacing for better wrapping */
  font-size: 3rem; /* Responsive font size */
}

h4 {
  font-size: 2.5rem; /* Responsive font size */
  padding: 20px;
  font-style: italic;
}

/* Platform section using Flexbox */
.TopPlatform,
.InfoSection,
.Contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  
}

.TopPlatform video,
.TopPlatform img,
.Contact input,
.Contact textarea,
.AboutDiv {
  max-width: 100%; /* Make full-width on small screens */
  margin: 15px;
  padding: 20px;
}

/* Flexbox layout for image/video grid */
.img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.img-box {
  flex: 1 1 calc(50% - 20px); /* Two columns layout */
  display: flex;
  justify-content: center;
  align-items: center;
}

img, video {
  max-width: 100%;
  height: auto;
}

/* Footer and Nav styles */
.footer,
nav {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  text-align: center;
  border-bottom: 2px solid #1e90ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 10px;
}

nav li a {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
}

nav li a:hover,
nav li a:focus,
.Contact button:hover,
.Contact button:focus {
  background-color: #1e90ff;
  color: #fff;
}

.InfoSection,
.AboutDiv {
  background-color: whitesmoke;
  color: purple;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Updated InfoSection layout */
.InfoSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: antiquewhite;
  border-radius: 10px;

}

.info-left,
.info-middle,
.info-right {
  flex: 1;
  text-align: center;
  
}

.info-left {
  text-align: left;
}

.info-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 22px;
  font-weight: bold;
}

.info-middle .info-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.info-middle li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.info_social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.info_social img, .info_social svg {
  width: 40px;
  height: 40px;
  margin: 0;
}

.info-right {
  text-align: right;
}

/* Contact form */
.Contact {
  background-image: url("https://media.istockphoto.com/id/1332357236/video/idyllic-emerald-river-in-lush-green-mountain-valley.jpg?s=640x640&k=20&c=Du5eYz4kS1hp8N6RXpTHc-5zLJzZtsL-1wU6W-0s89s=");
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
}

.Contact input,
.Contact textarea {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.Contact button {
  padding: 10px 20px;
  background-color: #1e90ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Updated AboutDiv and AboutMain */
.AboutMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  margin: auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.AboutDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin: 20px;
  padding: 50px;
  background-color: whitesmoke;
  color: purple;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 25px;
  line-height: 35pt;
}
.img-box
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

/* Responsive adjustments */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav li {
    margin: 5px 0;
  }

  .InfoSection {
    flex-direction: column;
    text-align: center;
  }

  .info-left, .info-right {
    text-align: center;
  }

  .info-middle {
    margin-top: 20px;
  }

  .AboutMain {
    padding: 10px;
  }

  .AboutDiv {
    max-width: 90%;
  }
}

/* Additional responsive adjustments for smaller screens (max-width: 480px) */
@media (max-width: 480px) {
  .title {
    font-size: 2rem; /* Scale title down */
  }
  
 
    .title h5 {
      font-size: 1.2rem; /* Reduce font size for smaller screens */
      letter-spacing: 0; /* Remove letter-spacing to prevent overflow */
      word-wrap: break-word; /* Ensure long words wrap */
      overflow-wrap: break-word; /* Handle long words gracefully */
      text-align: center; /* Ensure the text is centered on small screens */
    }

  
  h4 {
    font-size: 1.8rem;
  }

  .img-box {
    flex: 1 1 100%; /* Stack images vertically */
    margin-bottom: 20px;
  }

  .InfoSection, .Contact, .AboutMain {
    padding: 10px;
  }

  .info_social img, .info_social svg {
    width: 30px;
    height: 30px;
  }
}

/* Utility Classes */
.opacity-75 {
  opacity: 75%;
}

.text-skyblue {
  color: skyblue;
}