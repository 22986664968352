/* Planets section */
.planets {
    position: relative;
    width: 100%;
    height: 500px; /* Default height for larger screens */
    overflow: hidden;
    margin-top: 20px;
    border-radius: 10px;
}

/* Individual planet items */
.planet-item {
    position: absolute;
    width: 12vw; /* Use viewport width for scalability on larger screens */
    height: 12vw; /* Use vw for scalable height */
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Ensure the images fit within their container */
.planet-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the container without being distorted */
    object-position: center;
}

/* Fixed positioning for each planet with percentage-based values */
.planet-2 { top: 15%; left: 10%; }
.planet-3 { top: 35%; left: 65%; }
.planet-4 { top: 60%; left: 10%; }
.planet-5 { top: 68%; left: 75%; }
.planet-6 { top: 72%; left: 32%; }
.planet-7 { top: 20%; left: 85%; }
.planet-8 { top: 10%; left: 25%; }

/* Responsive adjustments for tablets and smaller devices */
@media (max-width: 768px) {
    .planets {
        height: 300px; /* Adjust height for smaller screens */
    }

    .planet-item {
        width: 20vw; /* Adjust size for tablets */
        height: 20vw;
    }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 480px) {
    .planets {
        height: 200px; /* Further reduce height for mobile */
    }

    .planet-item {
        width: 25vw; /* Larger size for smaller screens */
        height: 25vw;
    }

    /* Adjust positioning to fit smaller screens */
    .planet-2 { top: 10%; left: 5%; }
    .planet-3 { top: 30%; left: 60%; }
    .planet-4 { top: 50%; left: 5%; }
    .planet-5 { top: 60%; left: 70%; }
    .planet-6 { top: 65%; left: 30%; }
    .planet-7 { top: 15%; left: 80%; }
    .planet-8 { top: 5%; left: 20%; }
}

 /* Center images within their containers */
 .planets img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }